import React, { useState, useEffect }  from "react"
import Layout from "../components/layout"
import axios from "axios";
import { motion } from "framer-motion"


const Book = ({data}) => {
  const [dest, setDest] = useState();
  const [depDate, setDepDate] = useState('');
  const [days, setDays] = useState('');
  const [passengers, setPassengers] = useState('1');
  const isBrowser = typeof window !== "undefined"

  useEffect(() => {
    if (!isBrowser) {
      return;
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    setDest(urlParams.get('destination'))
    setDepDate(urlParams.get('date'))
    setPassengers(urlParams.get('passengers'))
  })

  const Text = () => <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ type: "linear", stiffness: 100 }}
      exit={{ opacity: 0 }}
      ><div className="form-success text-[8vw] md:text-lg text-white text-center">Thanks for your interest! <br/> We will get back to you shortly.</div></motion.div>;

    const [serverState, setServerState] = useState({
      submitting: false,
      status: null,
    });

    const [showText, setShowText] = useState(false);


    const handleServerResponse = (ok, msg, form) => {
      setServerState({
        submitting: false,
        status: { ok, msg }
      });
      if (ok) {
        setShowText(true);
        form.reset();
      }
    };

  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: "https://getform.io/f/3c06130c-9bd1-44c8-800d-c0091a8414af",
      data: new FormData(form)
    })
      .then(r => {
        handleServerResponse(true, "Thanks!", form);
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };

  return (
    <Layout headerColor="bg-grey opacity-1">
      <section className="book book-sec bg-grey-book py-[30vw] md:py-[15vw] lg:py-[12vw]">
        <div className="container-v">
        {showText ? <Text /> : null}
        {!showText &&
          <>
          <h2 className="text-[8vw] md:text-lg text-white text-center">Book Your Trip</h2>
          <form className="mt-[5vw]" onSubmit={handleOnSubmit}>
            <div class="md:flex md:space-x-[2.5vw]">
              <div className="input-group md:w-[20vw]">
                <label className="w-100 text-white">First Name</label>
                <input type="text" name="fname" required/>
              </div>
              <div className="input-group md:w-[20vw]">
                <label className="w-100 text-white">Last Name</label>
                <input type="text" name="lname" required/>
              </div>
              <div className="input-group md:w-[30vw]">
                <label className="w-100 text-white">Email Address</label>
                <input type="email" name="email" required />
              </div>
              <div className="input-group md:w-[30vw]">
                <label className="w-100 text-white">Phone</label>
                <input type="phone" name="phone" required />
              </div>
            </div>
            <div class="md:flex md:space-x-[2.5vw] md:mt-[3vw]">
              <div className="input-group md:w-[45vw]">
                <label className="w-100 text-white">Destination</label>
                <select name="destination" value={dest} onChange={(e) => setDest(e.default)}>
                  <option>THE ABACOS, BAHAMAS</option>
                  <option>EXUMA, BAHAMAS</option>
                </select>
              </div>
              <div className="input-group md:w-[20vw]">
                <label className="w-100 text-white">Date of departure</label>
                <input type="date" name="date" value={depDate} onChange={(e) => setDepDate(e.default)} required/>
              </div>
              <div className="input-group md:w-[20vw]">
              <label className="w-100 text-white">Number of days</label>
              <select>
                <option>7 Days</option>
              </select>
              </div>
              <div className="input-group md:w-[20vw]">
                <label className="w-100 text-white">Number of passengers</label>
                <input type="number" name="passengers" value={passengers} onChange={(e) => setPassengers(e.default)} required />
              </div>
            </div>
            <div className="mt-[5vw] text-center">
              <button type="submit" className="btn btn-lrg btn-yellow bg-yellow text-dark-blue">Request Reservation</button>
            </div>
          </form>
          </>
        }
        </div>
      </section>
    </Layout>
  )
}

export default Book
